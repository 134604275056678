import React from 'react'
import { LocalizedLink as Link } from 'gatsby-theme-i18n'

import course1 from '../../assets/images/courses/course1.jpg'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

import { useIntl } from 'react-intl'
import { gql, useQuery } from "@apollo/client"
import client from "../../utils/apollo-client"

const GET_COURSE = gql`
query($id: ID!) {
    course(id: $id) {
      data {
        id
        attributes {
          title
          description
          slug
          certificate
          who
          duration
          price
          image{data{attributes{url}}}
        }
      }
    }
  }
`;

// functional component
const CoursesDetailsSidebar = (id: any) => {

    const { loading, error, data } = useQuery(GET_COURSE, {
        client: client,
        variables: {
            id: id.id
        }
    })
    const intl = useIntl();

    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='bk7McNUjWgw' 
                onClose={() => setIsOpen(!isOpen)} 
            /> 

            <div className="courses-details-info">
                <div className="image">
                    <img src={course1} alt="course1" />
                    
                    <Link 
                        to="#" 
                        onClick={e => {e.preventDefault(); openModal()}} 
                        className="link-btn popup-youtube"
                    >
                    </Link>

                    <div className="content">
                        <i className="flaticon-google-play"></i>
                        <span>Course Preview</span>
                    </div>
                </div>

                <ul className="info">
                    <li className="price">
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-price-tag"></i> Price</span>
                            $49
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-teacher"></i> Instructor</span>
                            Sarah Taylor
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-stopwatch"></i> Duration</span>
                            7 weeks
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-distance-education"></i> Lessons</span>
                            25
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-web"></i> Enrolled</span>
                            255 students
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-open-padlock"></i> Access</span>
                            Lifetime
                        </div>
                    </li>
                </ul>

                <div className="btn-box">
                    <Link to="#" className="default-btn">
                        <i className="flaticon-shopping-basket"></i> 
                        Add to Cart <span></span>
                    </Link>
                    <Link to="#" className="default-btn">
                        <i className="flaticon-price-tag"></i> 
                        Buy Now <span></span>
                    </Link>
                </div>

                <div className="courses-share">
                    <div className="share-info">
                        <span>Share This Course <i className="flaticon-share"></i></span>

                        <ul className="social-link">
                            <li>
                                <a href="https://www.facebook.com/" className="d-block" target="_blank" rel="noreferrer">
                                    <i className='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" className="d-block" target="_blank" rel="noreferrer">
                                    <i className='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" className="d-block" target="_blank" rel="noreferrer">
                                    <i className='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" className="d-block" target="_blank" rel="noreferrer">
                                    <i className='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CoursesDetailsSidebar