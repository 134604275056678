import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CourseDetailsContent from '../components/CoursesDetails/CourseDetailsContent'
import { useIntl } from 'react-intl'


const CourseDetails = (location: any) => {
    const intl = useIntl()

    let id = location?.location?.state?.id;
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle={intl.formatMessage({ id: "coursedetails" })} 
                homePageText={intl.formatMessage({ id: "home" })} 
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "coursedetails" })}//"Course Details" 
            />
            <CourseDetailsContent id={id}/>
            <Footer />
        </Layout>
    );
}

export default CourseDetails